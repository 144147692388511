import { isClassicVehicleCoverage } from './vehicleUtils';
import {
  MOTOR_VEHICLE_CLASSIC_COVER,
  MOTOR_VEHICLE_COMPREHENSIVE_COVER,
} from '../constants/vehicleConstants';
import {
  OPTIONAL_EXTRAS_ROADSIDE_ASSIST_ENTHUSIAST,
  OPTIONAL_EXTRAS_ROADSIDE_ASSIST_NOT_TAKEN,
} from '../constants/coverDetailsConstants';
import { PAYMENT_FREQUENCY } from '../constants/productConstants';
import { formatNumberIntoCurrency } from './formatters';
import {
  JEOPARDY_TYPE,
  SOFT_JEOPARDY_REASON_SUM_INSURED_EXCEEDS_MAX_VALUE,
} from '../constants/jeopardyConstants';

export const buildExcessData = (
  vehicleYear,
  excessData,
  filteredVoluntaryExcess,
  standardExcess,
) => {
  filteredVoluntaryExcess.forEach((volExcess) => {
    if (
      isClassicVehicleCoverage(vehicleYear) &&
      volExcess.subValue === MOTOR_VEHICLE_CLASSIC_COVER
    ) {
      excessData.push({
        text: `$${Number(standardExcess) + Number(volExcess.value)}`,
        value: Number(standardExcess) + Number(volExcess.value),
      });
    }
    if (
      !isClassicVehicleCoverage(vehicleYear) &&
      volExcess.subValue === MOTOR_VEHICLE_COMPREHENSIVE_COVER
    ) {
      excessData.push({
        text: `$${Number(standardExcess) + Number(volExcess.value)}`,
        value: Number(standardExcess) + Number(volExcess.value),
      });
    }
  });
  return excessData;
};

export const roadSideAssistantValue = (value) =>
  value
    ? OPTIONAL_EXTRAS_ROADSIDE_ASSIST_ENTHUSIAST
    : OPTIONAL_EXTRAS_ROADSIDE_ASSIST_NOT_TAKEN;

export const getDisplayPremium = (
  paymentFrequency,
  annualPremium,
  monthlyPremium,
) =>
  paymentFrequency === PAYMENT_FREQUENCY.ANNUAL
    ? formatNumberIntoCurrency(annualPremium)
    : formatNumberIntoCurrency(monthlyPremium);

export const hasSoftJeopardy = (response) =>
  response.attributes?.jeopardyDetails?.status ===
    JEOPARDY_TYPE.SOFT_JEOPARDY &&
  response.attributes?.jeopardyDetails.warnings.length > 0;

export const isSoftJeopardySumInsuredExceedMaxValue = (
  toggleOn,
  jeopardyDetails,
) =>
  toggleOn === 'true' &&
  SOFT_JEOPARDY_REASON_SUM_INSURED_EXCEEDS_MAX_VALUE ===
    jeopardyDetails.warnings[0].code &&
  jeopardyDetails.agreedValueMax !== undefined;
