// Quote flow
export const HOME_PAGE = '/';
export const DOD_PAGE = '/your-duty-to-us';
export const POLICY_START_DATE_PAGE = '/policy-start-date';
export const VEHICLE_SEARCH_PAGE = '/vehicle-search';
export const ENTHUSIAST_DRIVER_PAGE = '/enthusiast-driver';
export const MODIFICATIONS_ACCESSORIES_PAGE = '/modifications-and-accessories';
export const SPECIFIED_MODIFICATIONS_ACCESSORIES_PAGE =
  '/specified-modifications-and-accessories';
export const RUST_AND_DAMAGE_PAGE = '/existing-damage';
export const VEHICLE_CONDITION_PAGE = '/vehicle-condition';
export const SUM_INSURED = '/agreed-value';
export const VEHICLE_FINANCE_PAGE = '/vehicle-finance';
export const VEHICLE_REGISTRATION_PAGE = '/registration-status';
export const VEHICLE_USAGE_PAGE = '/vehicle-usage';
export const VEHICLE_USAGE_FREQUENCY = '/vehicle-usage-frequency';
export const PARKING_TYPE_PAGE = '/parking-type';
export const ADDRESS_SEARCH_FLOW = '/address-search';
export const ADDRESS_SEARCH_STRUCTURED_ADDRESS_URL =
  '/address-search-structured-address';
export const ADDRESS_SEARCH_PARTIAL_MATCH_URL = '/address-search-partial-match';
export const MAIN_DRIVER_DETAILS_PAGE = '/main-driver-details';
export const MAIN_DRIVER_CLAIMS_PAGE = '/main-driver-claims';
export const DRIVER_SUMMARY_PAGE = '/driver-summary';
export const ADDITIONAL_DRIVER_DETAILS_PAGES_ROUTE =
  '/driver-:driverIndex-details';
export const POLICY_HOLDER_CRIMINAL_STATUS_PAGE =
  '/policy-holder-criminal-status';
export const NO_CLAIM_BONUS_PAGE = '/no-claim-discount';
export const CAPTURE_CONTACT_DETAILS_PAGE = '/capture-contact-details';
export const QSP_PAGE = '/quote-schedule';
export const POLICY_HOLDER_INSURANCE_CANCELLATION_PAGE =
  '/policy-holder-insurance-history';
export const POLICY_HOLDER_BANKRUPTCY_PAGE = '/policy-holder-bankruptcy';

// Buy flow
export const REGISTRATION_NUMBER_PAGE = '/registration-number';
export const VEHICLE_IDENTIFICATION_PAGE = '/vehicle-identification';
export const MAIN_DRIVER_EXTRA_DETAILS_PAGE = '/main-driver-extra-details';
export const MAIN_DRIVER_SUSPENSIONS_PAGE = '/main-driver-suspensions';
export const ADDITIONAL_DRIVER_EXTRA_PAGES_ROUTE =
  '/driver-:driverIndex-extraDetails';
export const ADDITIONAL_DRIVER_CLAIMS_PAGES_ROUTE =
  '/driver-:driverIndex-claimDetails';
export const ADDITIONAL_DRIVER_SUSPENSIONS_PAGES_ROUTE =
  '/driver-:driverIndex-suspensions';
export const POLICY_COMPANY_DETAILS_PAGE = '/policy-company-details';
export const MARKETING_CONSENT_PAGE = '/marketing-consent';
export const CAR_FINANCE_PROVIDER = '/car-finance-provider';
export const POLICYHOLDER_ADDRESS_PAGE = '/policyholder-address';
export const CONTACT_DETAILS_PAGE = '/contact-details';
export const COMMUNICATION_PREFERENCE_PAGE = '/communication-preference';

// Other Routes
export const SUNPAY_CALLBACK_PAGE = '/sunpay-callback';
export const CONFIRMATION_PAGE = '/confirmation';
export const CONFIRMATION_PRINT_PAGE = '/confirmation-print';

export const TIMEOUT_PAGE = '/session-timeout';
export const TECH_ERROR_PAGE = '/tech-error';
export const JEOPARDY_PAGE = '/jeopardy';
export const BUSINESS_ERROR_PAGE = '/business-error';

// Entry Routes
export const RETRIEVE_QUOTE_PAGE = '/retrieve-quote';

// External Website URLs
export const WEBSITE_LINK = 'https://www.shannons.com.au/';
export const PDS_LINK =
  'https://www.shannons.com.au/insurance/policy-documents/';
export const TERMS_AND_PRIVACY_STATEMENT_LINK =
  'https://www.shannons.com.au/terms/';
export const PRIVACY_STATEMENT_LINK = 'https://www.shannons.com.au/privacy/';
export const CONTACT_URL = 'https://www.shannons.com.au/contact/form/';
export const OSP_URL = 'https://online.shannons.com.au/onesuncorp';
export const CTP_NSW_URL =
  'https://online.ctp.shannons.com.au/nsw-registered-vehicle';
export const FEEDBACK_URL =
  'https://suncorp.mcxplatform.com.au/cgi-bin/qwebcorporate?idx=7RYEUA';

export const INSURANCE_COUNCIL_CONTACT_LINK =
  'https://insurancecouncil.com.au/';
export const INSURANCE_COUNCIL_CONTACT_TEL = 'tel:1300728228';
export const INSURANCE_COUNCIL_CONTACT_NUMBER = '1300 728 228';

export const AAMI_COMPREHENSIVE_QUOTE_LINK =
  'https://www.aami.com.au/secure/comprehensive-car-insurance-get-a-quote.html';

// Internal resource URLs
export const SHANNONS_LOGO_LINK = `${process.env.REACT_APP_STYLEGUIDE_BASE_URL}/img/logos/shannons.svg`;
export const AAMI_LOGO_LINK = `${process.env.REACT_APP_STYLEGUIDE_BASE_URL}/img/logos/aami.svg`;
export const COMPETITION_OPT_IN_LINK = `${process.env.REACT_APP_COMPETITION_OPT_IN_LINK}`;

// Google reCAPTCHA URLs
export const GOOGLE_PRIVACY = 'https://policies.google.com/privacy';
export const GOOGLE_TOS = 'https://policies.google.com/terms';
